import React, { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogBackdrop, DialogTitle } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon, CheckIcon } from "@heroicons/react/24/outline";

function Terms() {
  const [data, setData] = useState(null);
  const [sendingContact, setSendingContact] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [tandc, setTandc] = useState(true)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  function scrollTo(id) {
    const section = document.querySelector("#" + id);

    setMobileMenuOpen(false);

    if (section) {
      setTimeout(() => {
        section.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }, 200);
    }
  }

  return (
    <>


      <div className="h-[70vh] bg-hero-pattern-s lg:bg-hero-pattern bg-cover bg-center relative flex items-center justify-center lg:bg-fixed bg-no-repeat">
        <div className="w-4/5 lg:w-3/4 flex flex-col items-start text-left z-20">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-white font-brygada xs:-mt-16">
            Terms & Conditions
          </h1>
        </div>

        <div className="absolute h-[70vh] w-full opacity-50 top-0 z-10"></div>

        <div id="header_container" className="w-full fixed top-0 z-40">
          <div className="bg-primary h-16 w-full m-auto">
            <div className="w-11/12 lg:w-3/4 m-auto flex items-center h-full">
              <p className="text-slate-100 text-left w-full flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="min-h-4 min-w-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                07412466007
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="min-h-6 min-w-6 md:w-6 md:h-6 ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
                info@poundspooches.co.uk
              </p>
            </div>
          </div>

          <div className="bg-white h-24 w-full m-auto">
            <div className="w-[90%] lg:w-3/4 m-auto flex items-center h-full justify-between">
              {/* Logo */}
              <div>
                <a href="/">
                  <img src="logo.png" className="h-16 w-32" />
                </a>
              </div>

              <Dialog
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
                className="lg:hidden"
              >
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 ">
                  <div className="flex items-center justify-between">
                    <a href="/" className="-m-1.5 p-1.5">
                      <span className="sr-only">Your Company</span>
                      <img alt="" src="logo.png" className="h-16 w-32" />
                    </a>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon aria-hidden="true" className="size-6" />
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                      <div className="space-y-2 py-6">
                        <a
                          href="/"
                          className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                          onClick={() => scrollTo("about_us")}
                        >
                          About Us
                        </a>
                        <a
                          href="/"
                          className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                          onClick={() => scrollTo("services")}
                        >
                          Services
                        </a>
                        <a
                          href="/"
                          className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                          onClick={() => scrollTo("gallery")}
                        >
                          Gallery
                        </a>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </Dialog>

              <div className="flex lg:hidden">
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(true)}
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="size-6" />
                </button>
              </div>

              {/* Inline Text */}
              <div className="hidden gap-2 lg:gap-12 lg:flex">
                <a
                  className="relative cursor-pointer mt-1"
                  href="/"
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    About us
                  </p>
                </a>

                <a
                  className="relative cursor-pointer mt-1"
                   href="/"
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    Services
                  </p>
                </a>

                <a
                  className="relative cursor-pointer mt-1"
                  href="/"
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    Gallery
                  </p>
                </a>

                <a
                  className="relative cursor-pointer mt-1"
                  href="/"
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    Contact us
                  </p>
                </a>
              </div>

              <div class="hidden lg:flex lg:gap-3">
                <a
                  href="https://www.facebook.com/people/Pounds-Pooches/61570351520689/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full"
                >
                  <span class="sr-only">Facebook</span>

                  <svg
                    class="size-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/poundspooches"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full"
                >
                  <span class="sr-only">Instagram</span>
                  <svg
                    class="size-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  href="https://www.tiktok.com/@poundspooches"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full"
                >
                  <span class="sr-only">YouTube</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-6 my-1"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 w-full z-30">
          <svg
            id="visual"
            viewBox="0 0 1440 320"
            className="w-full h-auto"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <path
              d="M0 275L40 271.2C80 267.3 160 259.7 240 258.7C320 257.7 400 263.3 480 270.3C560 277.3 640 285.7 720 287.8C800 290 880 286 960 284.7C1040 283.3 1120 284.7 1200 284.5C1280 284.3 1360 282.7 1400 281.8L1440 281L1440 321L1400 321C1360 321 1280 321 1200 321C1120 321 1040 321 960 321C880 321 800 321 720 321C640 321 560 321 480 321C400 321 320 321 240 321C160 321 80 321 40 321L0 321Z"
              fill="#d2c1c1"
            />
          </svg>
        </div>

        

        
      </div>

      <div className="bg-primary w-full pb-16" id="about_us">
        <div className="w-3/4 m-auto grid grid-cols-1 gap-16">
          <div className="pt-16 justify-center">
            <p className="mt-4 text-pretty text-xl font-medium text-slate-50 sm:text-xl">
            TIME KEEPING
            <br/>
Please be punctual. I work by appointment only, the appointment slot allocated for your pooches groom has been booked at your request exclusively. I do not double book appointments, a no-show is lost income. I also offer a free appointment reminder, by text or phone. Just tell me your preference.
Most grooms take between 1-3 hours or more depending on the size and temperament of the pooch, and its coat condition. You will be advised what time to collect your pooch.
It is advisable not to return to the salon before this time, as your presence may distract your pooch during the finishing stage of its groom. It is essential that you collect your pooch at the agreed time as I work on a one to one basis and this will destrupt my next groom. Late collections may incur an extra charge.
<br/>
HOWEVER…
<br/>
Clients arriving more than 30 minutes late for their appointment can be considered a missed appointment and may have to re-book, this will incur a charge of 50% of the cost of the groom. If you need an earlier drop off/later collection time due to other commitments, please discuss this with me when booking.
<br/>
<br/>
CANCELLING YOUR DOG’S APPOINTMENT
<br/>
I respectfully request 48 hours notice if you need to cancel your pooches appointment. The slot can then be offered to other clients.
<br/>
<br/>
NO-SHOWS & LATE CANCELLATIONS (of less than 48 hours notice)
<br/>
A fee of 50% of your usual groom price may be levied for missed appointments and late cancellations.
<br/>
<br/>
NEGLECTED & MATTED COATS
<br/>
In the event that your pooches coat needs to be clipped down to release it from matting or coat neglect, Pounds Pooches, are not liable for any post-grooming effects of this procedure, which is not without risk.
Any humane de-matting will be undertaken solely at my discretion, in compliance with The Animal Welfare Act 2006 (clause 5 - Animals are to be protected from pain, injury and suffering). De-matting during a routine groom is limited to a maximum of 15 minutes and is only possible if the matting is minimal. For severely matted coats where de-matting isn't possible, the only humane way to deal with this will be to completely shave off the coat.
You will be given an estimate of how long matt release is likely to take and an estimated cost for the procedure. A shavedown consumes significantly more time, labour, product and tool/equipment wear and tear than a routine trim therefore will incur an extra charge. All shavedown costs are payable in full at the time you collect your pooch.
<br/>
<br/>
AGGRESSIVE & POORLY SOCIALISED DOGS: THE DANGEROUS DOGS ACT
<br/>
I reserve the right to refuse to groom any pooch at risk of harming me or itself. If your pooch is accepted for grooming and displays aggression that I have not been informed of during its session, the groom will be terminated immediately. You will be contacted to collect your pooch. The full starting price of the groom will be levied.
You must inform me prior to grooming if your pooch has ever bitten or displayed aggression, failure to disclose this information may result in refusal of further services. Your attention is drawn to the Dangerous Dogs Act, which holds owners liable for bites and injuries caused by their pooches.
<br/>
<br/>
YOUR PET’S HEALTH & WELFARE
<br/>
Whilst your pooch is in our care their health and welfare is our primary concern. Your pooch will only ever be groomed according to their tolerance of the process. If your pooch is fearful of any of the procedures employed during grooming, a workaround or alternative approach will be employed. Occasionally during grooming a pre-existing condition may be discovered which you may not have been aware of. We will notify you of this so that you can seek veterinary advice. If your pooch becomes ill whilst in our care we may call a Vet and unless it can be clearly shown that Pounds Pooches are liable, all Vet costs shall be at the owner's expense. Although extremely unlikely, accidents can happen during grooming. In the even of an accident or serious medical issue arising during your pooches groom you authorise us to seek emergency veterinary treatment. If an injury becomes apparent after the grooming session, it must be reported within 48 hours otherwise we are unable to accept any liability.
<br/>
<br/>GROOM FEEDBACK
<br/>
You will be given detailed feedback after every groom. If you have any queries or need further clarification, please just ask or contact us at a later stage. If you are dissatisfied with any aspect of your pooches groom please let us know before you leave. Reasonable requests to alter the trim will be dealt with immediately or changes noted for the next groom. We are more than happy to disclose any details of your pooches groom or advise you about how best to care for your pooches coat.
<br/>
<br/>
PRICING
<br/>
Starting prices are for pooches;
That are used to being professionally groomed on a regular schedule (a MINIMUM of once per season, 4 times per year depending on breed).
Whose coats are well maintained between professional grooms (kept tangle, knot and matt free by regular brushing or combing).
<br/>
You can reasonably expect to pay more than the starting price for ;
Matted, poorly maintained and / or overgrown coats.
Pooches which are difficult to manage or uncooperative during grooming.
Overweight pooches or oversized for the breed standard.
Timid, anxious or nervous pooches, which require frequent breaks and may need special handling.
These cost more time, labour and/or product. The costs are passed on to you. If Pounds Pooches are unable to complete a groom due to behavioural problems or aggression you will be charged the starting price of the groom. All costs are payable in full at the time you collect your pooch.
 
            </p>
          </div>
        </div>
      </div>

     
    </>
  );
}

export default Terms;
